import React from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons"
import Left from "../../images/svg/right-arrowwhite.svg"

const servicesportfolio = () => {
  return (
    <section id="call-to-actions" className="wow fadeInUp  ">
      <div className="container py-4 ">
        <div className="row">
          <div className="services-caption text-center">
            <h4 className="heading fw-bold about-heading ">
              CREATIVE SHOWCASE
            </h4>
            <p className="started-textthree">
              SHOWCASING OUR PROJECTS THAT INSPIRE US TO DO MORE
            </p>
          </div>
          <div className="col-lg-12 portfolio-text">
            <p className="cta-text">
              {" "}
              VIEW PORTFOLIO  <img src={Left} alt="" className="navi-rights"></img>    
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default servicesportfolio
