import React from "react"

const design = () => {
  return (
    <section className="exclusive">
      <div className="container pt-4">
        <div className="row">
          <div className="col-12 col-lg-12">
            <p className="services-digital  text-center">
              Advertising that designs experiences for connecting with people
            </p>
            <p className="story-text">
              Advertising has evolved over time and it keeps taking new forms.
              We are here to provide you the perfect blend of advertising and
              promotion to help you reach the masses and create brand awareness.
              We aim at empowering businesses at a global scale with
              personalized promotional strategies.
            </p>
            <p className="story-text">
              We are here to create a promotion strategy for you that convinces
              your customers to engage with your brand. Mass Media Creatives
              provides a thoughtfully crafted mix of advertising and promotion
              to inform and remind the customers about your brand and to
              influence the buying behavior as well.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default design
