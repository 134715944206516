import React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import AdvertishingPromotionalPage from "../../components/services/advertising-promotional/index"
const advertishingpromotionalPage = () => {
  return (
    <Layout>
      <Seo
        title="Services - Advertishing &amp; Promotional Mix"
        desc=""
        pathname=""
        keywords=""
      />
      <AdvertishingPromotionalPage />
    </Layout>
  )
}

export default advertishingpromotionalPage
