import React from "react"
import { Link } from "gatsby"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons"
import box from "../../../images/svg/menu.svg"
import Right from "../../../images/svg/left-arrow.svg"
import Left from "../../../images/svg/right-arrows.svg"

const advertisingservice = () => {
  return (
    <>
      <section className="exclusive">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-4">
              <h1 className="page-title exclusive-graphic">
                Advertishing Areas
              </h1>
              <ul className="service-graphic">
                <li>Hoarding / Bill Board</li>
                <li>Dispaly Banner</li>
                <li>wall Poster</li>
                <li>Lamp Poster</li>
                <li>No Parking Signs</li>
                <li>Mobile Van / Roadshow</li>
                <li>Auto / Cab / Bus Advertising</li>
                <li>Theatre Advertising</li>
                <li>Gantry Advertising</li>
                <li>Signal Lamp Post</li>
                <li>Centre Median Banner</li>
                <li>Traffic Umbrella</li>
                <li>Mall Branding</li>
                <li>Railway / Airline</li>
              </ul>
            </div>
            <div className="col-12 col-lg-2 d-none d-lg-block"></div>
            <div className="col-12 col-lg-4 d-none d-lg-block">
              <h1 className="page-title exclusive-graphic">
                Promotional gifts
              </h1>
              <ul className="service-graphic">
                <li>Bags </li>
                <li>Pens &amp; Pencils</li>
                <li>Bandanas </li>
                <li>Bandanas </li>
                <li> Notebook</li>
                <li>Drinkwar</li>
                <li>Lanyard </li>
                <li>Ornaments </li>
                <li>Umbrellas </li>
                <li>Watches </li>
                <li>Bottle Openers</li>
                <li>Pouches </li>
                <li>Phone Holders &amp; Mounts</li>
                <li>Caps &amp; Hats</li>
              </ul>
            </div>
            <div className="col-12 col-lg-12 text-center page-next py-4">
              <div className="d-flex align-items-center justify-content-center">
              <Link to="/services/digital-marketing">
                {" "}
                <img src={Right} alt="" className="navigation-left"></img>{" "}
                <span>
                Digital Marketing
                </span>
             
              </Link>
              <img src={box} className="navigation-btn" alt="" ></img>
              <Link to="/services/events-exhibitions">
                {" "}
                <span>
                Events &amp; Exhibitions
                </span>
               
                <img src={Left} alt="" className="navigation-right"></img>     
              </Link>
              </div>
             
            </div>
  
          </div>
        </div>
      </section>
    </>
  )
}

export default advertisingservice
