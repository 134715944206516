import React from "react"
import Slider from "./slider"
import Advertising from "./advertising"
import Gallery from "./gallery"
import Design from "./design"
import Services from "./services"
import Portfolio from "../../portfolio/portfolio"
import Service from "../../home/services"
import Clients from "../../home/clients"

const advertishingpage = () => {
  return (
    <>
      <Slider />
      <Advertising />
      <Gallery />
      <Design />
      <Services />
      <Portfolio />
      <Service />
      <Clients />
    </>
  )
}

export default advertishingpage
