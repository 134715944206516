import React from "react"

import gallery from "../../../images/showcase/Image 42.png"

const galleryadvertishing = () => {
  return (
    <>
      <section className="exclusive">
        <div className="helped">
          <img src={gallery} alt="gallery" />

          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="headprds position-relative">
                  <h4 className="text-start">
                    Advertising &amp; <br />
                    Promotional Mix
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p className="graphic-advertising text-center">Advertising</p>
      </section>
    </>
  )
}

export default galleryadvertishing
