import React from "react"

import titan from "../../images/clients/titan.png"
import vip from "../../images/clients/vip.png"
import hinduja from "../../images/clients/hindu.png"
import cafe from "../../images/clients/coffe.png"
import blackberry from "../../images/clients/Image-black.png"
import hellofm from "../../images/clients/hello.png"

const homeclient = () => {
  return (
    <>
      <section id="clients" className="clients clients  py-4">
        <div className="container">
          <div className="row">
            <div className="col-lg-2 col-md-4 col-6">
              <img
                src={titan}
                className="img-fluid"
                alt=""
                data-aos="zoom-in"
              />
            </div>

            <div className="col-lg-2 col-md-4 col-6">
              <img
                src={vip}
                className="img-fluid"
                alt=""
                data-aos="zoom-in"
                data-aos-delay="100"
              />
            </div>

            <div className="col-lg-2 col-md-4 col-6">
              <img
                src={hinduja}
                className="img-fluid"
                alt=""
                data-aos="zoom-in"
                data-aos-delay="200"
              />
            </div>

            <div className="col-lg-2 col-md-4 col-6">
              <img
                src={cafe}
                className="img-fluid"
                alt=""
                data-aos="zoom-in"
                data-aos-delay="300"
              />
            </div>

            <div className="col-lg-2 col-md-4 col-6">
              <img
                src={blackberry}
                className="img-fluid"
                alt=""
                data-aos="zoom-in"
                data-aos-delay="400"
              />
            </div>

            <div className="col-lg-2 col-md-4 col-6">
              <img
                src={hellofm}
                className="img-fluid"
                alt=""
                data-aos="zoom-in"
                data-aos-delay="500"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default homeclient
