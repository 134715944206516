import React from "react"

import advertising from "../../../images/showcase/image_05.png"

const advertisingslider = () => {
  return (
    <>
      <section className="header-about design-header bg-darker text-center py-0 about-slider">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-lg-7 mt-2 mt-lg-0">
              <ul className="text-start  list-unstyled mb-0  ">
                <li className="d-flex">
                  <div className="ml-4">
                    <h2 className="title mb-2 slider-hero">sERVICES</h2>
                    <h3 className="title mb-2 about-slider">
                      Take advantage of our{" "}
                      <span className="fw-bold">360 Degree</span>
                      <br />
                      Digital Solutions
                    </h3>
                  </div>
                </li>
              </ul>
            </div>

            <div className="d-none d-lg-block col-lg-5">
              <div className="text-center ml-0 ml-xl-5">
                <img
                  src={advertising}
                  alt="digital-slider"
                  className="img-fluid"
                ></img>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default advertisingslider
